import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import CountDownTimer from './CountDownTimer';

const TopSlider = () => {
  const [logo, setLogo] = useState(false);
  const [bg, setBg] = useState('img/slider/home_bg2.jpg');

  const changeBackground = () => {
    if (window.scrollY >= window.innerHeight / 3) {
      setLogo(true);
    } else {
      setLogo(false);
    }
  };

  window.addEventListener('scroll', changeBackground);

  useEffect(() => {
    if (window.innerWidth < 480) {
      setBg('/img/slider/home_bg12.jpg');
    } else {
      setBg('/img/slider/home_bg2.jpg');
    }
  }, [window.innerWidth]);

  const settings = {
    dots: false,
    arrow: false,
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    speed: 5000,
  };

  const partnerList = [
    { img: 'home_bg2', animationDealay: '' },
    // { img: 'home_bg7', animationDealay: '30' },
    // { img: 'home_bg4', animationDealay: '60' },
    // { img: 'home_bg8', animationDealay: '90' },
    // { img: 'home_bg6', animationDealay: '120' },
  ];

  return (
    <div className='slider-four'>
      <div className='tm_hero' id='home' data-style='one'>
        <div className='background' style={{ backgroundImage: `url(${bg})` }} />
        {/* <Slider {...settings}>
            {partnerList.map((val, i) => (
              <li className='item' key={i}>
                <img
                  src={`img/slider/${val.img}.jpg`}
                  alt='sliders'
                  data-aos='fade'
                  data-aos-duration='1200'
                  data-aos-delay={val.animationDealay}
                />
              </li>
            ))}
            {/* End single parter image */}
        {/* </Slider> */}
        {/* End .background */}

        <div className='container'>
          <div className={`content ${logo && `hide`}`}>
            <div className='name_wrap2'>
              <h3>
                <img src={`${process.env.PUBLIC_URL + '/img/logo/logo.png'}`} className='logo_top' />
              </h3>
            </div>
            {/* End title */}

            <div className='job_wrap'>
              <span className='job'>
                Thanks for Joining us in celebrating our big day. <br />
                We will update the photos soon.
              </span>
              {/* <CountDownTimer /> */}
            </div>
            {/* End designation */}
            <a
              href='https://www.facebook.com/100008019523464/videos/1071467750398901/'
              target='_blank'
              className='white-fill-bg btn-outline btn-medium'>
              OUR WEDDING VIDEO
            </a>
          </div>
          {/* End content */}
        </div>
        {/* End .container */}
      </div>
    </div>
  );
};

export default TopSlider;
