import { axiosInstance } from 'utility/constants';

export const searchFacilityByPage = (pageSize = 1000, pageIndex = 1, searchData) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/facility/list`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      limit: pageSize,
      page: pageIndex + 1,
      filters: {
        ...searchData,
      },
    },
  });

export const createFacility = (data) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/facility/add`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      data,
    },
  });

export const createFacilityServices = (id, services) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/facility/add_services/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      services,
    },
  });

export const getFacilityById = (id) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/facility/view/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const getFacilityServicesById = (id) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/facility_service/view/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const updateFacility = (id, data) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/facility/update/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      data,
    },
  });

export const updateFacilityServices = (id, services) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/facility/update_timeslots/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      services,
    },
  });

export const deleteFacility = (id) =>
  axiosInstance({
    method: 'DELETE',
    url: `/api/cms/manager/facility/delete/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const deleteFacilityServices = (id) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/facility_service/delete/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {},
  });
