import React from 'react';

const Footer = () => {
  return (
    <>
      <div className='tm_section'>
        <div className='tm_copyright'>
          <div className='container'>
            <div className='inner'>
              <p>&copy; {new Date().getFullYear()} by Anna & Perry . All rights reserved.</p>
            </div>
            {/* End inner */}
          </div>
        </div>
        {/* End tm_copyright */}
      </div>
    </>
  );
};

export default Footer;
