import { axiosInstance } from 'utility/constants';

export const searchInvoiceCategoryByPage = (pageSize = 1000, pageIndex = 1, searchData) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/item_cat/list`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      limit: pageSize,
      page: pageIndex + 1,
      filters: {
        ...searchData,
      },
    },
  });

export const createInvoiceCategory = (data) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/item_cat/add`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      data,
    },
  });

export const getInvoiceCategoryById = (id) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/item_cat/view/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const updateInvoiceCategory = (id, data) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/item_cat/update/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      data,
    },
  });

export const deleteInvoiceCategory = (id) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/item_cat/delete/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
  });
