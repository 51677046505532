import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { useStyles } from '@material-ui/core/styles';
import TopSlider from 'core/pages/Home/TopSlider';
import About from 'core/pages/Home/About';
import Portfolio from 'core/pages/Home/Portfolio';
import Map from 'core/pages/Home/Map';

import Header from 'core/pages/_layout/Header';
import Footer from 'core/pages/_layout/Footer';

import useHomeStyle from './Home/styles';

const Home = () => {
  const { t } = useTranslation();
  const classes = useHomeStyle();

  return (
    <Grid container itme xl={12} className='home-four'>
      <Header />
      <TopSlider />
      <About />
      <Map />
      <Portfolio />
      <Footer />
    </Grid>
  );
};

export default Home;
