import { axiosInstance } from 'utility/constants';

export const searchNoticeTypeByPage = (pageSize = 1000, pageIndex = 1, searchData) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/notice_type/list`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      limit: pageSize,
      page: pageIndex+1,
      filters: {
        ...searchData,
      },
    },
  });

export const createNoticeType = (data) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/notice_type/add`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      data,
    },
  });

export const getNoticeTypeById = (id) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/notice_type/view/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const updateNoticeType = (id, data) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/notice_type/update/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      data,
    },
  });

export const deleteNoticeType = (id) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/notice_type/delete/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
  });
