import { axiosInstance } from 'utility/constants';

export const searchFacilityTypeByPage = (pageSize = 1000, pageIndex = 1, searchData) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/facility_type/list`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      limit: pageSize,
      page: pageIndex + 1,
      filters: {
        ...searchData,
      },
    },
  });

export const createFacilityType = (data) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/facility_type/add`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      data,
    },
  });

export const getFacilityTypeById = (id) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/facility_type/view/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      id,
    },
  });

export const updateFacilityType = (id, data) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/facility_type/update/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      data,
    },
  });

export const deleteFacilityType = (id) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/facility_type/delete/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
  });
