import { axiosInstance } from 'utility/constants';

export const searchNoticeCategoryByPage = (pageSize = 1000, pageIndex = 1, searchData) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/notice_cat/list`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      limit: pageSize,
      page: pageIndex+1,
      filters: {
        ...searchData,
      },
    },
  });

export const createNoticeCategory = (data) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/notice_cat/add`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      data,
    },
  });

export const getNoticeCategoryById = (id) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/notice_cat/view/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const updateNoticeCategory = (id, data) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/notice_cat/update/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      data,
    },
  });

export const deleteNoticeCategory = (id) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/notice_cat/delete/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
  });
