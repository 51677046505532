import { axiosInstance } from 'utility/constants';

export const searchUnitByPage = (pageSize = 1000, pageIndex = 1, searchData) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/unit/list`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      limit: pageSize,
      page: pageIndex + 1,
      filters: {
        ...searchData,
      },
    },
  });

export const createUnit = (data) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/unit/add`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      data,
    },
  });

export const getUnitById = (id) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/unit/view/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const updateUnit = (id, data) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/unit/update/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  });

export const deleteUnit = (id) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/unit/delete/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
  });
