import React from 'react';

const About = () => {
  return (
    <>
      <div className='tm_section' id='about'>
        <div
          className='tm_about'
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + '/img/about/pink-bg.jpg'})`,
          }}>
          <div className='container'>
            <div className='about_inner'>
              {/* End left */}

              <div className='left'>
                <div className='tm_title' data-aos='fade-up' data-aos-duration='1200'>
                  <span>About Anna & Perry</span>
                  <h4>
                    Two become one marriage
                    <br />
                    0.5 + 0.5 = 1, not 1 + 1 = 2
                  </h4>
                </div>
                <div className='text' data-aos='fade-up' data-aos-duration='1200'>
                  <p>
                    Anna is a free wide girl, however Perry is a well planned person. Both of us having a long
                    adjustment period.
                    <br />
                    <br />
                    We get married after 7 years relationship, which extend our love. It's a new chapter for us to learn
                    and step forward.
                  </p>
                </div>
                <div className='tm_button' data-aos='fade-up' data-aos-duration='1200'>
                  <a href='https://www.facebook.com/groups/1600545463676337' download>
                    Facebook Live
                  </a>
                </div>
              </div>
              <div className='right'>
                <div className='image' data-aos='fade-up' data-aos-duration='1200'>
                  <img src={`${process.env.PUBLIC_URL + '/img/about/profile.png'}`} alt='placeholder' />
                  <div
                    className='main'
                    style={{
                      backgroundImage: `url(${process.env.PUBLIC_URL + '/img/about/profile.png'})`,
                    }}></div>
                </div>

                {/* End image */}
              </div>
              {/* End right */}
            </div>
          </div>
          {/* End container */}
        </div>
      </div>
    </>
  );
};

export default About;
