import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import SimpleReactLightbox from 'simple-react-lightbox';
import { SRLWrapper } from 'simple-react-lightbox';
import Masonry from '@mui/lab/Masonry';

const tabList = ['In-house', 'Sham Shui Po', 'Central'];
const heights = [150, 30, 90, 70, 110, 150, 130, 80, 50, 90, 100, 150, 30, 50, 80];

const tabListContent = [
  {
    imgItems: [
      {
        img: '0001_WIL00001',
        title: 'In-house 01',
        meta: 'In-house',
        imgLink: '',
        delayAnimation: '0',
      },
      {
        img: '0008_WIL00010',
        title: 'In-house 02',
        meta: 'In-house',
        imgLink: '',
        delayAnimation: '100',
      },
      {
        img: '0016_WIL00025',
        title: 'In-house 03',
        meta: 'In-house',
        imgLink: '',
        delayAnimation: '200',
      },
      {
        img: '0018_WIL00030',
        title: 'In-house 04',
        meta: 'In-house',
        imgLink: '',
        delayAnimation: '0',
      },
      {
        img: '0029_ED_00012',
        title: 'In-house 05',
        meta: 'In-house',
        imgLink: '',
        delayAnimation: '100',
      },
      {
        img: '0033_ED_00013',
        title: 'In-house 06',
        meta: 'In-house',
        imgLink: '',
        delayAnimation: '200',
      },
      {
        img: '0042_WIL00063',
        title: 'In-house 07',
        meta: 'In-house',
        imgLink: '',
        delayAnimation: '0',
      },
      {
        img: '0063_WIL00086',
        title: 'In-house 08',
        meta: 'In-house',
        imgLink: '',
        delayAnimation: '100',
      },
      {
        img: '0066_WIL00091',
        title: 'In-house 09',
        meta: 'In-house',
        imgLink: '',
        delayAnimation: '200',
      },
      {
        img: '0074_WIL00105',
        title: 'In-house 10',
        meta: 'In-house',
        imgLink: '',
        delayAnimation: '0',
      },
      {
        img: '0080_WIL00110',
        title: 'In-house 11',
        meta: 'In-house',
        imgLink: '',
        delayAnimation: '100',
      },
      {
        img: '0088_WIL00113',
        title: 'In-house 12',
        meta: 'In-house',
        imgLink: '',
        delayAnimation: '200',
      },
      {
        img: '0090_ED_00029',
        title: 'In-house 13',
        meta: 'In-house',
        imgLink: '',
        delayAnimation: '0',
      },
      {
        img: '0095_WIL00118',
        title: 'In-house 14',
        meta: 'In-house',
        imgLink: '',
        delayAnimation: '100',
      },
      {
        img: '0101_ED_00031',
        title: 'In-house 15',
        meta: 'In-house',
        imgLink: '',
        delayAnimation: '200',
      },
      {
        img: '0114_WIL00139',
        title: 'In-house 16',
        meta: 'In-house',
        imgLink: '',
        delayAnimation: '0',
      },
      {
        img: '0115_ED_00036',
        title: 'In-house 17',
        meta: 'In-house',
        imgLink: '',
        delayAnimation: '100',
      },
      {
        img: '0143_WIL00162',
        title: 'In-house 18',
        meta: 'In-house',
        imgLink: '',
        delayAnimation: '200',
      }, //

      {
        img: '0153_ED_00048',
        title: 'In-house 19',
        meta: 'In-house',
        imgLink: '',
        delayAnimation: '0',
      },
      {
        img: '0161_WIL00176',
        title: 'In-house 20',
        meta: 'In-house',
        imgLink: '',
        delayAnimation: '100',
      },
      {
        img: '0172_WIL00186',
        title: 'In-house 21',
        meta: 'In-house',
        imgLink: '',
        delayAnimation: '200',
      },
      {
        img: '0174_WIL00188',
        title: 'In-house 22',
        meta: 'In-house',
        imgLink: '',
        delayAnimation: '0',
      },
      {
        img: '0176_WIL00190',
        title: 'In-house 23',
        meta: 'In-house',
        imgLink: '',
        delayAnimation: '100',
      },
      {
        img: '0185_WIL00197',
        title: 'In-house 24',
        meta: 'In-house',
        imgLink: '',
        delayAnimation: '200',
      },
      {
        img: '0188_WIL00200',
        title: 'In-house 25',
        meta: 'In-house',
        imgLink: '',
        delayAnimation: '0',
      },
      {
        img: '0200_WIL00212',
        title: 'In-house 26',
        meta: 'In-house',
        imgLink: '',
        delayAnimation: '100',
      },
      {
        img: '0212_ED_00057',
        title: 'In-house 27',
        meta: 'In-house',
        imgLink: '',
        delayAnimation: '200',
      },
      {
        img: '0224_WIL00234',
        title: 'In-house 28',
        meta: 'In-house',
        imgLink: '',
        delayAnimation: '0',
      },
      {
        img: '0230_WIL00240',
        title: 'In-house 29',
        meta: 'In-house',
        imgLink: '',
        delayAnimation: '100',
      },
      {
        img: '0232_WIL00242',
        title: 'In-house 30',
        meta: 'In-house',
        imgLink: '',
        delayAnimation: '200',
      },
      {
        img: '0237_WIL00246',
        title: 'In-house 31',
        meta: 'In-house',
        imgLink: '',
        delayAnimation: '0',
      },
      {
        img: '0239_WIL00248',
        title: 'In-house 32',
        meta: 'In-house',
        imgLink: '',
        delayAnimation: '100',
      },
      {
        img: '0241_WIL00249',
        title: 'In-house 33',
        meta: 'In-house',
        imgLink: '',
        delayAnimation: '200',
      },
      {
        img: '0244_ED_00066',
        title: 'In-house 34',
        meta: 'In-house',
        imgLink: '',
        delayAnimation: '0',
      },
      {
        img: '0254_WIL00260',
        title: 'In-house 35',
        meta: 'In-house',
        imgLink: '',
        delayAnimation: '100',
      },
      {
        img: '0263_WIL00268',
        title: 'In-house 36',
        meta: 'In-house',
        imgLink: '',
        delayAnimation: '200',
      },

      {
        img: '0265_WIL00269',
        title: 'In-house 37',
        meta: 'In-house',
        imgLink: '',
        delayAnimation: '100',
      },
      {
        img: '0270_WIL00273',
        title: 'In-house 38',
        meta: 'In-house',
        imgLink: '',
        delayAnimation: '200',
      },
      {
        img: '0275_WIL00277',
        title: 'In-house 39',
        meta: 'In-house',
        imgLink: '',
        delayAnimation: '0',
      },
    ],
  },
  {
    imgItems: [
      {
        img: '0278_ED_00072',
        title: 'Nam Shan Estate 01',
        meta: 'Nam Shan Estate',
        imgLink: '',
        delayAnimation: '0',
      },
      {
        img: '0288_WIL00284',
        title: 'Nam Shan Estate 02',
        meta: 'Nam Shan Estate',
        imgLink: '',
        delayAnimation: '100',
      },
      {
        img: '0294_ED_00080',
        title: 'Nam Shan Estate 03',
        meta: 'Nam Shan Estate',
        imgLink: '',
        delayAnimation: '200',
      },
      {
        img: '0308_WIL00308',
        title: 'Nam Shan Estate 04',
        meta: 'Nam Shan Estate',
        imgLink: '',
        delayAnimation: '0',
      },
      {
        img: '0310_WIL00311',
        title: 'Nam Shan Estate 05',
        meta: 'Nam Shan Estate',
        imgLink: '',
        delayAnimation: '100',
      },
      {
        img: '0320_WIL00319',
        title: 'Nam Shan Estate 06',
        meta: 'Nam Shan Estate',
        imgLink: '',
        delayAnimation: '200',
      },
      {
        img: '0322_ED_00093',
        title: 'Nam Shan Estate 07',
        meta: 'Nam Shan Estate',
        imgLink: '',
        delayAnimation: '0',
      },
      {
        img: '0340_WIL00338',
        title: 'Nam Shan Estate 08',
        meta: 'Nam Shan Estate',
        imgLink: '',
        delayAnimation: '100',
      },
      {
        img: '0342_WIL00341',
        title: 'Nam Shan Estate 09',
        meta: 'Nam Shan Estate',
        imgLink: '',
        delayAnimation: '200',
      },
      {
        img: '0355_WIL00354',
        title: 'Nam Shan Estate 10',
        meta: 'Nam Shan Estate',
        imgLink: '',
        delayAnimation: '0',
      },
      {
        img: '0361_ED_00103',
        title: 'Nam Shan Estate 11',
        meta: 'Nam Shan Estate',
        imgLink: '',
        delayAnimation: '100',
      },
      {
        img: '0384_WIL00375',
        title: 'Nam Shan Estate 12',
        meta: 'Nam Shan Estate',
        imgLink: '',
        delayAnimation: '200',
      },
      {
        img: '0278_ED_00072',
        title: 'Nam Shan Estate 13',
        meta: 'Nam Shan Estate',
        imgLink: '',
        delayAnimation: '0',
      },
      {
        img: '0288_WIL00284',
        title: 'Nam Shan Estate 14',
        meta: 'Nam Shan Estate',
        imgLink: '',
        delayAnimation: '100',
      },
      {
        img: '0294_ED_00080',
        title: 'Nam Shan Estate 15',
        meta: 'Nam Shan Estate',
        imgLink: '',
        delayAnimation: '200',
      },
      {
        img: '0308_WIL00308',
        title: 'Nam Shan Estate 16',
        meta: 'Nam Shan Estate',
        imgLink: '',
        delayAnimation: '0',
      },
      {
        img: '0310_WIL00311',
        title: 'Nam Shan Estate 17',
        meta: 'Nam Shan Estate',
        imgLink: '',
        delayAnimation: '100',
      },
      {
        img: '0320_WIL00319',
        title: 'Nam Shan Estate 18',
        meta: 'Nam Shan Estate',
        imgLink: '',
        delayAnimation: '200',
      },
      {
        img: '0322_ED_00093',
        title: 'Nam Shan Estate 19',
        meta: 'Nam Shan Estate',
        imgLink: '',
        delayAnimation: '0',
      },
      {
        img: '0340_WIL00338',
        title: 'Nam Shan Estate 20',
        meta: 'Nam Shan Estate',
        imgLink: '',
        delayAnimation: '100',
      },
      {
        img: '0342_WIL00341',
        title: 'Nam Shan Estate 21',
        meta: 'Nam Shan Estate',
        imgLink: '',
        delayAnimation: '200',
      },
      {
        img: '0355_WIL00354',
        title: 'Nam Shan Estate 22',
        meta: 'Nam Shan Estate',
        imgLink: '',
        delayAnimation: '0',
      },
      {
        img: '0361_ED_00103',
        title: 'Nam Shan Estate 23',
        meta: 'Nam Shan Estate',
        imgLink: '',
        delayAnimation: '100',
      },
      {
        img: '0384_WIL00375',
        title: 'Nam Shan Estate 24',
        meta: 'Nam Shan Estate',
        imgLink: '',
        delayAnimation: '200',
      },
      {
        img: '0397_WIL00385',
        title: 'Tai Nam Street 01',
        meta: 'Tai Nam Street',
        imgLink: '',
        delayAnimation: '0',
      },
      {
        img: '0406_WIL00394',
        title: 'Tai Nam Street 02',
        meta: 'Tai Nam Street',
        imgLink: '',
        delayAnimation: '100',
      },
      {
        img: '0420_ED_00118',
        title: 'Tai Nam Street 03',
        meta: 'Tai Nam Street',
        imgLink: '',
        delayAnimation: '200',
      },
      {
        img: '0466_WIL00441',
        title: 'Tai Nam Street 09',
        meta: 'Tai Nam Street',
        imgLink: '',
        delayAnimation: '200',
      },
      {
        img: '0426_WIL00407',
        title: 'Tai Nam Street 04',
        meta: 'Tai Nam Street',
        imgLink: '',
        delayAnimation: '0',
      },
      {
        img: '0436_ED_00127',
        title: 'Tai Nam Street 05',
        meta: 'Tai Nam Street',
        imgLink: '',
        delayAnimation: '100',
      },
      {
        img: '0441_WIL00415',
        title: 'Tai Nam Street 06',
        meta: 'Tai Nam Street',
        imgLink: '',
        delayAnimation: '200',
      },
      {
        img: '0445_ED_00133',
        title: 'Tai Nam Street 07',
        meta: 'Tai Nam Street',
        imgLink: '',
        delayAnimation: '0',
      },
      // {
      //   img: '0452_WIL00423',
      //   title: 'Tai Nam Street 08',
      //   meta: 'Tai Nam Street',
      //   imgLink: '',
      //   delayAnimation: '100',
      // },
    ],
  },
  {
    imgItems: [
      {
        img: '0478_WIL00453',
        title: 'Central 01',
        meta: 'Central',
        imgLink: '',
        delayAnimation: '0',
      },
      {
        img: '0502_WIL00477',
        title: 'Central 02',
        meta: 'Central',
        imgLink: '',
        delayAnimation: '100',
      },
      {
        img: '0508_WIL00483',
        title: 'Central 03',
        meta: 'Central',
        imgLink: '',
        delayAnimation: '200',
      },
      {
        img: '0510_WIL00485',
        title: 'Central 04',
        meta: 'Central',
        imgLink: '',
        delayAnimation: '0',
      },
      {
        img: '0531_WIL00506',
        title: 'Central 05',
        meta: 'Central',
        imgLink: '',
        delayAnimation: '100',
      },
      {
        img: '0538_WIL00513',
        title: 'Central 06',
        meta: 'Central',
        imgLink: '',
        delayAnimation: '200',
      },
      {
        img: '0539_WIL00514',
        title: 'Central 07',
        meta: 'Central',
        imgLink: '',
        delayAnimation: '0',
      },
      {
        img: '0551_WIL00526',
        title: 'Central 08',
        meta: 'Central',
        imgLink: '',
        delayAnimation: '100',
      },
      {
        img: '0555_WIL00530',
        title: 'Central 09',
        meta: 'Central',
        imgLink: '',
        delayAnimation: '200',
      },
      {
        img: '0572_WIL00547',
        title: 'Central 10',
        meta: 'Central',
        imgLink: '',
        delayAnimation: '0',
      },
    ],
  },
];

const Portfolio = () => {
  return (
    <div className='tm_section' id='pre-wedding'>
      <SimpleReactLightbox>
        <div className='tm_portfolio'>
          <div className='container'>
            <div className='positon-relative'>
              <div className='tm_title'>
                <div className='title_flex'>
                  <div className='left'>
                    <span>Pre-wedding</span>
                    <h3>Our Pre-wedding</h3>
                  </div>
                </div>
              </div>
              {/* End tm_title */}

              <div className='portfolio_filter'>
                <Tabs>
                  <TabList>
                    {tabList.map((val, i) => (
                      <Tab key={i}>{val}</Tab>
                    ))}
                  </TabList>
                  {/* End tablist */}

                  <div className='portfolio_list has-effect'>
                    <SRLWrapper>
                      {tabListContent.map((tabContent, i) => (
                        <TabPanel key={i}>
                          <Masonry className='gallery_zoom'>
                            {tabContent.imgItems.map((item, i) => (
                              <li
                                key={i}
                                sx={heights[i]}
                                data-aos='fade-right'
                                data-aos-duration='1200'
                                data-aos-delay={item.delayAnimation}>
                                <div className='inner'>
                                  <div className='entry tm_portfolio_animation_wrap'>
                                    <a href={`/img/portfolio/${item.img}.JPG`}>
                                      <img src={`/img/portfolio/${item.img}.JPG`} alt={item.meta} />
                                    </a>
                                  </div>
                                  <div className='mobile_title'>
                                    <h3>
                                      <a href={item.imgLink} rel='noopener noreferrer' target='_blank'>
                                        {item.title}
                                      </a>
                                    </h3>
                                    <span>{item.meta}</span>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </Masonry>
                        </TabPanel>
                      ))}
                    </SRLWrapper>
                    {/* End tabpanel */}
                  </div>
                  {/* End list wrapper */}
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </SimpleReactLightbox>
    </div>
  );
};

export default Portfolio;
