import moment from 'moment';
import { DATE_FORMAT, DATETIME_FORMAT } from 'utility/constants';
import { replacePhoneNumber } from 'utility/index';
import useDropdown from './useDropdown';

const useForm = (formContext) => {
  const dispatch = formContext?.dispatch;
  const state = formContext?.state;
  const { filterBuilding, filterFloor, filterUnit } = useDropdown(formContext);
  // Init Form Inputs
  const initFormInputs = (formInputs) => {
    formInputs &&
      Object.keys(formInputs)
        .filter((section) => typeof formInputs[section] === 'object' && section !== 'layout' && section !== 'history')
        .map((section) => {
          const { isShown, isEnabled, isValid, isTouched, isRequired } = formInputs[section];

          Object.keys(formInputs[section].fields).map((item) => {
            formInputs[section].fields[item].isShown = isShown.includes(item);
            formInputs[section].fields[item].isEnabled = isEnabled.includes(item);
            formInputs[section].fields[item].isValid = isValid.includes(item);
            formInputs[section].fields[item].isTouched = isTouched.includes(item);
            formInputs[section].fields[item].isRequired = isRequired.includes(item);

            if (formInputs[section].fields[item].type === 'multiple') {
              // console.log(formInputs[section].fields[item]);
              const multipleField = formInputs[section].fields[item];
              const { isMuiShown, isMuiEnabled, isMuiValid, isMuiTouched, isMuiRequired } = multipleField;
              const { multiple = {} } = multipleField;

              multiple &&
                Object.keys(multiple).length > 0 &&
                Object.keys(multiple).map((subItem) => {
                  Object.keys(multiple[subItem])
                    .filter((subInput) => typeof multiple[subItem][subInput] === 'object')
                    .map((subInput) => {
                      const splitWith = subInput.split('_');
                      multiple[subItem][subInput].isShown = isMuiShown.includes(splitWith[0]);
                      multiple[subItem][subInput].isEnabled = isMuiEnabled.includes(splitWith[0]);
                      multiple[subItem][subInput].isValid = isMuiValid.includes(splitWith[0]);
                      multiple[subItem][subInput].isTouched = isMuiTouched.includes(splitWith[0]);
                      multiple[subItem][subInput].isRequired = isMuiRequired.includes(splitWith[0]);

                      if (multiple[subItem][subInput].type === 'multipleChild') {
                        // console.log(multiple[subItem][subInput]);
                        const {
                          isMuiChildShown,
                          isMuiChildEnabled,
                          isMuiChildValid,
                          isMuiChildTouched,
                          isMuiChildRequired,
                          multipleChild,
                        } = multiple[subItem][subInput];

                        multipleChild &&
                          Object.keys(multipleChild).length > 0 &&
                          Object.keys(multipleChild).map((subSubItem) => {
                            Object.keys(multipleChild[subSubItem])
                              .filter((subSubInput) => typeof multipleChild[subSubItem][subSubInput] === 'object')
                              .map((subSubInput) => {
                                const splitWithChild = subSubInput.split('_');

                                multipleChild[subSubItem][subSubInput].isShown = isMuiChildShown.includes(
                                  splitWithChild[0],
                                );
                                multipleChild[subSubItem][subSubInput].isEnabled = isMuiChildEnabled.includes(
                                  splitWithChild[0],
                                );
                                multipleChild[subSubItem][subSubInput].isValid = isMuiChildValid.includes(
                                  splitWithChild[0],
                                );
                                multipleChild[subSubItem][subSubInput].isTouched = isMuiChildTouched.includes(
                                  splitWithChild[0],
                                );
                                multipleChild[subSubItem][subSubInput].isRequired = isMuiChildRequired.includes(
                                  splitWithChild[0],
                                );
                                return multipleChild[subSubItem][subSubInput];
                              });

                            return multipleChild;
                          });
                      }

                      return multiple[subItem][subInput].isShown;
                    });

                  return multiple;
                });
            }

            return formInputs[section][item];
          });

          return formInputs[section];
        });

    return formInputs;
  };
  //  Check Valid
  //  Check Valid
  const formRequiredArr = (formInputs) => {
    const tmpCheckedField = [];
    let formField = [];

    Object.keys(formInputs)
      .filter(
        (section) =>
          section &&
          typeof formInputs[section] === 'object' &&
          section !== 'layout' &&
          section !== 'history' &&
          section !== 'templateLinks' &&
          formInputs.layout.isSectionShown.includes(section),
      )
      .map((section) => {
        const { fields } = formInputs[section];
        const formFieldParent = Object.keys(fields).filter(
          (item) => fields[item].isRequired && fields[item].isShown && fields[item].type !== 'multiple',
        );

        const formFieldMultiple = Object.keys(fields)
          .filter((item) => fields[item].type === 'multiple' && fields[item].isRequired)
          .map((item) =>
            Object.keys(fields[item].multiple).map((subItem) =>
              Object.keys(fields[item].multiple[subItem]).filter(
                (multipleItem) =>
                  fields[item].multiple[subItem][multipleItem].isRequired &&
                  fields[item].multiple[subItem][multipleItem].isShown,
              ),
            ),
          );

        if (formFieldMultiple.length > 0) {
          tmpCheckedField.push(formFieldParent.concat(formFieldMultiple[0].flat()));
        } else {
          tmpCheckedField.push(formFieldParent);
        }
        return tmpCheckedField;
      });

    tmpCheckedField.map((item) => {
      formField = [...formField, ...item];
      return formField;
    });
    // console.log(formField);
    return formField;
  };

  const formValidArr = (formInputs) => {
    const tmpCheckedField = [];
    let formField = [];

    Object.keys(formInputs)
      .filter(
        (section) =>
          section &&
          typeof formInputs[section] === 'object' &&
          section !== 'layout' &&
          section !== 'history' &&
          section !== 'templateLinks' &&
          formInputs.layout.isSectionShown.includes(section),
      )
      .map((section) => {
        const { fields } = formInputs[section];
        const formFieldParent = Object.keys(fields).filter(
          (item) => fields[item].isRequired && fields[item].isValid && fields[item].type !== 'multiple',
        );

        const formFieldMultiple = Object.keys(fields)
          .filter((item) => fields[item].type === 'multiple' && fields[item].isRequired)
          .map((item) =>
            Object.keys(fields[item].multiple).map((subItem) =>
              Object.keys(fields[item].multiple[subItem]).filter(
                (multipleItem) =>
                  fields[item].multiple[subItem][multipleItem].isRequired &&
                  fields[item].multiple[subItem][multipleItem].isValid,
              ),
            ),
          );

        if (formFieldMultiple.length > 0) {
          tmpCheckedField.push(formFieldParent.concat(formFieldMultiple[0].flat()));
        } else {
          tmpCheckedField.push(formFieldParent);
        }
        return tmpCheckedField;
      });

    tmpCheckedField.map((item) => {
      formField = [...formField, ...item];
      return formField;
    });
    // console.log(formField);
    return formField;
  };

  const checkAllValid = (FormInputs) =>
    JSON.stringify(formRequiredArr(FormInputs)) === JSON.stringify(formValidArr(FormInputs));

  //  Handle Link
  const handleTabLink = (path, target) => window.open(`http://${path}`, !target ? '_self' : target);

  const handleLink = (path) => (window.location.href = path);

  //  Handle FormInput
  const getValueFromInput = (formInput, e) => {
    const { name: formInputName, type } = formInput;

    switch (type) {
      case 'text':
        formInput.value = e.target.value;
        break;
      case 'number':
        formInput.value = e?.floatValue;
        break;
      case 'textArea':
        // const editorState = convertToRaw(e.getCurrentContent());
        //   console.log(formInput, e);
        //  formInput.value = convertToRaw(e.getCurrentContent());
        break;
      case 'password':
        formInput.value = e.target.value;
        break;
      case 'radiobutton':
        formInput.value = e.target.value;
        break;
      case 'switcher':
        formInput.value = !formInput.value;
        break;
      case 'checkbox': {
        const checkedBox = formInput?.data;
        // console.log(checkedBox[0].checked);
        checkedBox[0].checked = !checkedBox[0].checked;
        checkedBox[0].value = checkedBox[0].checked;
        formInput.value = checkedBox[0].checked;
        break;
      }
      case 'dropdown':
        // console.log(e);
        formInput.value = e; // {"label": "xxxx", "value": "xxxxx"}
        formInput.isValid = true;
        break;
      case 'asyncDropdown':
        formInput.value = e; // [{ label: e[formInput['getOptionLabelField']], value: e[formInput['getOptionValueField']] }];
        // console.log(e);
        break;
      case 'date':
        formInput.value = e;
        break;
      case 'dateTime':
        formInput.value = e ? moment(e).format(DATETIME_FORMAT) : '';
        // console.log(formInput.value);
        break;
      case 'dateTimeRange': {
        // console.log(e.split('|')[0]);
        const startDate = e.split('|')[0] === 'startDate' ? moment(e.split('|')[1]).format(DATETIME_FORMAT) : '';
        const endDate = e.split('|')[0] === 'endDate' ? moment(e.split('|')[1]).format(DATETIME_FORMAT) : '';

        formInput.startDate = moment(startDate).toDate();
        formInput.endDate = moment(endDate).toDate();
        break;
      }
      case 'dateRange': {
        const [dateName, dateString] = e.split('|');
        // console.log(e);

        if (dateName === 'startDate') {
          formInput.startDate = moment(dateString).toDate();
        } else if (dateName === 'endDate') {
          formInput.endDate = moment(dateString).toDate();
        }
        // if (dateName === 'startDate') {
        //   formInput.startDate = dateString;
        // } else if (dateName === 'endDate') {
        //   formInput.endDate = dateString;
        // }

        break;
      }
      case 'timeRange': {
        formInput.value = e && e.length === 2 ? e : '';
        break;
      }
      case 'fileUpload': {
        if (e.length > 0) {
          const files = [];
          e.map((file, index) => {
            const newFile = {
              fileName: `${file?.name}`,
              systemName: `${formInput?.name}_${index}`,
              fileSize: `${file?.size}`,
              name: formInput?.name,
              type: file?.type,
              content: file,
            };

            if (formInput.isMulti) {
              files.push(newFile);
              formInput.value = files.length > 0 && [...formInput.value, ...files];
              formInput.isValid = files.length > 0;
            } else {
              files[0] = newFile;
              formInput.value = files;
              formInput.isValid = files.length > 0;
              // console.log(formInput);
            }

            return files;
          });
        } else {
          formInput.isValid = false;
        }

        break;
      }
      case 'multiple': {
        // console.log(e);
        break;
      }
      default:
        formInput.value = e.target.value;
    }

    return formInput;
  };

  const showEstateBuilding = (noticeType, section) => {
    dispatch({
      type: 'SHOW_HIDE_EBFU',
      noticeType,
      section,
    });
  };

  const checkRepeatPassword = (repeatPasswordValue, newPasswordName) => {
    dispatch({
      type: 'CHECK_REPEAT_PASSWORD',
      repeatPasswordValue,
      newPasswordName,
    });
  };

  const contentOrAttachment = (value, section, otherField) => {
    dispatch({
      type: 'EITHER_CONTENT_ATTACHMENT',
      value,
      section,
      otherField,
    });
  };

  const onInputChange = (e, formInput, pageAction, section, parentField) => {
    const changeFormInput = getValueFromInput(formInput, e, parentField);
    const { name: formInputName, value } = formInput;

    if (pageAction === 'search') {
      dispatch({
        type: 'UPDATE_SEARCH_INPUT',
        changeFormInput,
      });
    } else {
      if (formInputName.includes('building')) filterFloor(formInput?.value?.value, 'form', 'floor');
      if (formInputName.includes('floor')) filterUnit(formInput?.value?.value, 'form', 'unit');
      if (formInputName === 'notice_type') showEstateBuilding(formInput?.value?.value, 'form');
      if (formInputName === 'repeatPassword') checkRepeatPassword(formInput?.value, 'newPassword');
      if (section === 'replyDetails' && formInputName === 'content')
        contentOrAttachment(formInput?.value, 'replyDetails', 'attachment');

      if (!parentField) {
        dispatch({ type: 'UPDATE_FORM_INPUT_VALUE', changeFormInput, section });
      } else {
        dispatch({
          type: 'UPDATE_FORM_INPUT_MULTIPLE_VALUE',
          changeFormInput,
          section,
        });
      }
    }
  };

  const onCheckboxChildChange = (e, formInput, section) => {
    const changeFormInput = getValueFromInput(formInput, formInput?.type, e);

    dispatch({
      type: 'UPDATE_FORM_INPUT_CHECKBOX_CHILD_VALUE',
      title: 'searchInputs',
      changeFormInput,
      section,
    });
  };

  const onSearchLongInputChange = (e, formInput) => {
    formInput.value = e.target.value;

    dispatch({
      type: 'UPDATE_SEARCH_LONG_INPUT_VALUE',
      title: 'searchInputs',
      updateFormInput: formInput,
      section: 'longFields',
    });
  };

  const onCheckboxChange = (e, selectedFieldName) => {
    const checkboxNameArr = e.target.name.split('||');
    const resultName = checkboxNameArr[0];
    const checkColumn = checkboxNameArr[1];
    const code = checkboxNameArr[2];
    const currentPermission = state[resultName].filter((item) => item?.permissionCode === code);
    const newPermissionId = parseInt(checkboxNameArr[3], 10);
    const functionId = checkboxNameArr[4];
    // console.log(state[resultName]);

    if (e.target.checked === true) {
      currentPermission[0][selectedFieldName].push(newPermissionId);
    } else {
      currentPermission[0][selectedFieldName] = currentPermission[0][selectedFieldName].filter(
        (item) => item !== newPermissionId,
      );
    }

    dispatch({
      type: 'UPDATE_API_DATA_LIST',
      currentPermission,
      parentCode: code,
      listResultName: resultName,
      checkColumn,
      selectedFieldName,
      functionId,
    });
  };

  // Handle Multiple
  const onMultipleDragDrop = (result) => {
    if (!result.destination) return;
    const splitDraggableId = result.draggableId.split('_');
    const section = splitDraggableId[0];

    dispatch({
      type: 'UPDATE_MULTIPLE_ORDER',
      // multipleField,
      section,
      startIndex: result.source.index,
      endIndex: result.destination.index,
    });
  };
  const addNewMultiple = (multipleField, section) => dispatch({ type: 'ADD_NEW_MULTIPLE', multipleField, section });

  const removeMultiple = (multipleField, section, removeKey) =>
    dispatch({ type: 'REMOVE_MULTIPLE', multipleField, section, removeKey });
  //  Loading Account Id into dropdown

  const loadAsyncDropdown = async (type, keyword) => {
    const loadObj = {
      options: [],
    };
    const mapObj = {
      initiator: {
        path: `/api/user-picklist/users?nameKeyword=${keyword}&page=0&pageSize=100`,
        newValueName: 'loginId',
      },
      requester: {
        path: `/api/user-picklist/users?nameKeyword=${keyword}&page=0&pageSize=100`,
        newValueName: 'loginId',
      },
      accountIds: {
        path: `/api/ap3/common-picklist/v1/accounts?accountIdKeyword=${keyword}&page=0&pageSize=100&pageSorts=accountId`,
        newValueName: 'accountId',
      },
      departments: {
        path: `/api/user-picklist/departments?nameKeyword=${keyword}&page=0&pageSize=100`,
        newValueName: 'code',
      },
    };
    const res = await fetch(mapObj[type].path);
    const resJSON = await res.json();
    loadObj.options = [];
    resJSON.map((item) =>
      loadObj.options.push({
        label: `${item.name} (${item[mapObj[type].newValueName]})`,
        value: item[mapObj[type].newValueName],
      }),
    );

    return loadObj.options;
  };

  //  filtered the form Inputs only for calculation
  const filteredInputs = (Inputs) => {
    let newInputs = {};

    Object.keys(Inputs).map((item) => {
      Object.keys(Inputs[item])
        .filter(
          (sectionItem) =>
            typeof Inputs[item][sectionItem] === 'object' &&
            Inputs[item][sectionItem].value !== undefined &&
            Inputs[item][sectionItem].value !== null &&
            Inputs[item][sectionItem].type !== 'dataList' &&
            Inputs[item][sectionItem].type !== 'dataTable' &&
            // sectionItem !== 'title' &&
            sectionItem !== 'isShown' &&
            sectionItem !== 'isValid' &&
            sectionItem !== 'isEnabled' &&
            sectionItem !== 'isTouched' &&
            sectionItem !== 'isRequired' &&
            sectionItem !== 'value' &&
            sectionItem !== 'tmpValue',
        )
        .map((sectionItem) => {
          newInputs = {
            ...newInputs,
            [item]: {
              ...newInputs[item],
              [sectionItem]: {
                ...Inputs[item][sectionItem],
              },
            },
          };
          return newInputs;
        });
      return Inputs[item];
    });
    return newInputs;
  };

  //  Update Search API Url
  const searchApiUrl = (searchInputName, basicUrl, searchType) => {
    if (searchType === 'keyword') {
      const { longFields: searchFields } = state[searchInputName]?.search;

      searchFields &&
        Object.keys(searchFields)
          .filter((field) => typeof searchFields[field] === 'object')
          .map((field) => {
            basicUrl += searchFields[field].value ? `&${field}=${searchFields[field].value}` : '';
            return basicUrl;
          });
    } else if (searchType === 'advanced') {
      const { longFields, fields: advancedFields } = state[searchInputName]?.search;

      longFields &&
        Object.keys(longFields)
          .filter((field) => typeof longFields[field] === 'object')
          .map((field) => {
            basicUrl += longFields[field].value ? `&${field}=${longFields[field].value}` : '';
            return basicUrl;
          });

      advancedFields &&
        Object.keys(advancedFields)
          .filter((field) => typeof advancedFields[field] === 'object')
          .map((field) => {
            switch (advancedFields[field].type) {
              case 'dateRange': {
                // console.log(fields[field]?.startDate);
                basicUrl += advancedFields[field]?.startDate
                  ? `&${field}From=${moment(advancedFields[field]?.startDate).format('YYYY-MM-DD')}`
                  : '';
                basicUrl += advancedFields[field]?.endDate
                  ? `&${field}To=${moment(advancedFields[field]?.endDate).format('YYYY-MM-DD')}`
                  : '';
                break;
              }
              case 'dropdown': {
                basicUrl += advancedFields[field].value?.value ? `&${field}=${advancedFields[field].value?.value}` : '';
                break;
              }
              case 'asyncDropdown': {
                basicUrl += advancedFields[field].value?.value ? `&${field}=${advancedFields[field].value?.value}` : '';
                break;
              }
              default:
                // console.log(field);
                basicUrl += advancedFields[field].value ? `&${field}=${advancedFields[field].value}` : '';
            }
            return basicUrl;
          });
    }

    // console.log(basicUrl);

    return basicUrl;
  };

  const searchApiData = (searchInputName, searchType) => {
    let basicUrl = ``;
    let searchData = {};
    if (searchType === 'keyword') {
      const searchFields = state[searchInputName]?.search?.longFields;
      searchFields &&
        Object.keys(searchFields)
          .filter((field) => typeof searchFields[field] === 'object' && searchFields[field].value)
          .map((field) => {
            searchData = {
              ...searchData,
              name: searchFields[field].value,
            };
            return searchData;
          });
    } else if (searchType === 'advanced') {
      const longFields = state[searchInputName]?.search?.longFields;
      const advancedFields = state[searchInputName]?.search?.fields;
      longFields &&
        Object.keys(longFields)
          .filter((field) => typeof longFields[field] === 'object' && longFields[field].value)
          .map((field) => {
            searchData = {
              ...searchData,
              name: longFields[field].value,
            };
            return searchData;
          });

      advancedFields &&
        Object.keys(advancedFields)
          .filter((field) => typeof advancedFields[field] === 'object')
          .map((field) => {
            const { type, startDate, endDate, value } = advancedFields[field];

            switch (type) {
              case 'dateRange': {
                // console.log(fields[field]?.startDate);
                basicUrl += startDate ? `&${field}From=${moment(startDate).format('YYYY-MM-DD')}` : '';
                basicUrl += endDate ? `&${field}To=${moment(endDate).format('YYYY-MM-DD')}` : '';
                break;
              }
              case 'dropdown': {
                basicUrl += value?.value ? `&${field}=${value?.value}` : '';
                break;
              }
              case 'asyncDropdown': {
                basicUrl += value?.value ? `&${field}=${value?.value}` : '';
                break;
              }
              default:
                // console.log(field);
                basicUrl += value ? `&${field}=${value}` : '';
            }
            return basicUrl;
          });
    }

    // console.log(searchData);

    return searchData;
  };

  const exportApiData = (formInputs) => {
    let data = {};
    let attachmentsMap = {};
    const attachments = [];
    const splitSymbol = '_';

    Object.keys(formInputs)
      .filter(
        (section) =>
          typeof formInputs[section] === 'object' &&
          section !== 'layout' &&
          section !== 'history' &&
          section !== 'templateLinks',
      )
      .map((section) => {
        // console.log(section);
        const { fields } = formInputs[section];
        Object.keys(fields)
          .filter(
            (sectionItem) =>
              fields[sectionItem].value !== '' &&
              !sectionItem.includes('created_at') &&
              !sectionItem.includes('updated_at'),
          )
          .map((sectionItem) => {
            const { [sectionItem]: field } = fields;
            const {
              type,
              isMulti,
              name: fieldName,
              value: fieldValue,
              multiple: fieldMultiple,
              data: fieldData,
            } = field;

            const roleArr = [];
            switch (type) {
              case 'dropdown': {
                if (isMulti === false) {
                  // console.log(field?.value);
                  if (fieldName === 'roles') {
                    roleArr.push(fieldValue?.value);
                    data = {
                      ...data,
                      [sectionItem]: roleArr,
                    };
                  } else {
                    data = {
                      ...data,
                      [sectionItem]: fieldValue?.value,
                    };
                  }
                } else {
                  const selectedOptions = [];
                  if (typeof fieldValue === 'object') {
                    // console.log(field?.value?.value)
                    data = {
                      ...data,
                      [sectionItem]: fieldValue?.value,
                    };
                  } else if (field.value.length > 1) {
                    fieldValue.map((subItem) => selectedOptions.push(subItem?.value));
                    data = {
                      ...data,
                      [sectionItem]: selectedOptions,
                    };
                  }
                }
                break;
              }
              case 'asyncDropdown': {
                // console.log(formInputs[item][sectionItem]);
                // if (formInputs[item][sectionItem].isMulti === false) {
                data = {
                  ...data,
                  [sectionItem]: fieldValue?.value,
                };
                break;
              }
              case 'checkbox': {
                // console.log(formInputs[item][sectionItem].data[0].value);
                data = {
                  ...data,
                  [sectionItem]: fieldData[0].value,
                };
                break;
              }
              case 'date':
                // console.log(field?.value);
                data = {
                  ...data,
                  [sectionItem]: fieldValue ? moment(fieldValue).format(DATE_FORMAT) : '',
                };
                break;
              case 'fileUpload': {
                // console.log(formInputs[item][sectionItem].value);

                const attach = field?.value;

                attach.length > 0 &&
                  attach
                    .filter((AttItem) => AttItem?.content)
                    .map((AttItem) => {
                      attachmentsMap = { ...attachmentsMap, ...{ [`${AttItem?.systemName}`]: AttItem?.fileName } };
                      attachments.push(AttItem?.content);
                      return attachmentsMap;
                    });
                data = {
                  ...data,
                  attachmentsMap,
                  attachments,
                };
                break;
              }
              case 'multiple': {
                const dataArr = [];
                Object.keys(fieldMultiple)
                  .filter((item) => typeof fieldMultiple[item] === 'object')
                  .map((item) => {
                    let newObj = {};
                    Object.keys(fieldMultiple[item])
                      .filter(
                        (subItem) =>
                          typeof fieldMultiple[item][subItem] === 'object' && fieldMultiple[item][subItem].value !== '',
                      )
                      .map((subItem) => {
                        const subItemLength = subItem.split(`${splitSymbol}`).length;
                        const newId =
                          subItemLength >= 3
                            ? `${subItem.split(`${splitSymbol}`)[0]}_${subItem.split(`${splitSymbol}`)[1]}`
                            : subItem.split(`${splitSymbol}`)[0];

                        switch (fieldMultiple[item][subItem].type) {
                          case 'dropdown': {
                            newObj = { ...newObj, [newId]: fieldMultiple[item][subItem].value?.value };
                            break;
                          }
                          case 'switcher': {
                            // console.log(fieldMultiple[item][subItem].value);
                            newObj = { ...newObj, [newId]: fieldMultiple[item][subItem].value };

                            break;
                          }
                          default:
                            newObj = { ...newObj, [newId]: fieldMultiple[item][subItem].value };
                        }

                        return newObj;
                      });
                    dataArr.push(newObj);
                    return newObj;
                  });

                // console.log(dataArr);

                data = {
                  ...data,
                  [sectionItem]: dataArr,
                };

                break;
              }
              case 'text': {
                if (sectionItem === 'phone') replacePhoneNumber(fieldValue);

                data = {
                  ...data,
                  [sectionItem]: fieldValue,
                };
                break;
              }
              case 'number': {
                data = {
                  ...data,
                  [sectionItem]: field?.value,
                };
                break;
              }
              // case 'textArea': {

              // }
              default:
                data = {
                  ...data,
                  [sectionItem]: fieldValue,
                };
            }
            return data;
          });
        return formInputs[section];
      });
    return data;
  };

  const dispatchFormDetailsById = (formKey, formActions, changeFormInputs, pageAction) => {
    dispatch({
      type: 'GET_FORM_DETAILS_BY_ID',
      formKey,
      formActions,
      changeFormInputs,
      pageAction,
    });
  };

  const dispatchSearchResult = (searchResult, pageSize, pageIndex, totalCount) => {
    dispatch({
      type: 'SET_SEARCH_RESULT',
      searchResult,
      pageSize,
      pageIndex,
      totalCount,
    });
  };

  const filteredReadOnly = (listColumns) =>
    Object.keys(listColumns)
      .filter((item) => !item.includes('edit'))
      .reduce((obj, item) => {
        obj[item] = listColumns[item];
        return obj;
      }, {});

  const uploadFileTypeId = (type) => {
    let fileTypeId = 1;
    if (type.includes('image')) fileTypeId = 21;
    if (type.includes('video')) fileTypeId = 41;
    if (type.includes('audio')) fileTypeId = 61;
    if (type.includes('pdf')) fileTypeId = 81;

    return fileTypeId;
  };

  return {
    initFormInputs,
    checkAllValid,
    handleTabLink,
    handleLink,
    onInputChange,
    onCheckboxChange,
    onCheckboxChildChange,
    onSearchLongInputChange,
    onMultipleDragDrop,
    addNewMultiple,
    removeMultiple,
    loadAsyncDropdown,
    searchApiUrl,
    searchApiData,
    filteredReadOnly,
    filteredInputs,
    dispatchSearchResult,
    dispatchFormDetailsById,
    exportApiData,
    uploadFileTypeId,
  };
};

export default useForm;
