import { axiosInstance } from 'utility/constants';

export const searchBuildingByPage = (pageSize = 1000, pageIndex = 1) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/building/list`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      limit: pageSize,
      page: pageIndex + 1,
    },
  });

export const createBuilding = (data) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/building/add`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      data,
    },
  });

export const getBuildingById = (id) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/building/view/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const updateBuilding = (id, data) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/building/update/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      data,
    },
  });

export const deleteBuilding = (id) =>
  axiosInstance({
    method: 'DELETE',
    url: `/api/cms/manager/building/delete/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
  });
