import { axiosInstance } from 'utility/constants';

export const searchUserByPage = (pageSize = 1000, pageIndex = 0, searchData = {}, apiRole) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/user/list`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      limit: pageSize,
      page: pageIndex + 1,
      filters: {
        ...searchData,
        role: apiRole,
      },
    },
  });

export const createUser = (userData) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/user/add`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      data: userData,
    },
  });

export const createUserInfo = (userId) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/user_info/add`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      user: userId,
    },
  });

export const getUserById = (id) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/user/view/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const getUserDetailById = (id) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/auth/belongs`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      user_id: id,
    },
  });

export const updateUser = (id, userData) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/user/update/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      data: userData,
    },
  });

export const deleteUser = (id) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/user/delete/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const assignToUnit = (user, unit) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/auth/assign_to_unit`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      user_id: user,
      units: unit,
    },
  });

export const assignToBuilding = (user, buildings) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/auth/assign_to_building`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      user_id: user,
      buildings,
    },
  });

// update_password

export const setUserPassword = (user, password) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/user/set_password`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      user_id: user,
      password,
    },
  });
