import { axiosInstance } from 'utility/constants';

export const searchEstateByPage = (pageSize = 1000, pageIndex = 1) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/estate/list`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      limit: pageSize,
      page: pageIndex + 1,
    },
  });

export const searchEstateByPageAll = (pageSize = 1000, pageIndex = 1) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/estate/list?all`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      limit: pageSize,
      page: pageIndex + 1,
    },
  });

export const createEstate = (data) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/estate/add`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      data,
    },
  });

export const getEstateById = (id) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/estate/view/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const updateEstate = (id, data) =>
  axiosInstance({
    method: 'PUT',
    url: `/api/cms/manager/estate/update/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      data,
    },
  });

export const deleteEstate = (id) =>
  axiosInstance({
    method: 'DELETE',
    url: `/api/cms/manager/estate/delete/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
  });
