import { axiosInstance } from 'utility/constants';

export const searchFloorByPage = (pageSize = 1000, pageIndex = 1, searchData) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/floor/list`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      limit: pageSize,
      page: pageIndex+1,
      filters: {
        ...searchData,
      },
    },
  });

export const createFloor = (data) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/floor/add`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      data,
    },
  });

export const getFloorById = (id) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/floor/view/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const updateFloor = (id, data) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/floor/update/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      data,
    },
  });

export const deleteFloor = (id) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/floor/delete/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
  });
