import { makeStyles } from '@material-ui/styles';

const HomeStyles = makeStyles(theme => ({
  homeContainer: {
    // .home-four {
    //   .tm_hero .background {
    //     width: 100% !important;
    //   }
    //   .tm_hero .image {
    //     background-position: 100% 12%;
    //   }
    //   @media (max-width: 1040px) {
    //     .tm_hero .image {
    //       background-position: 71% 100%;
    //     }
    //   }
    
    //   .tm_hero[data-style='one'] .background {
    //     -webkit-clip-path: none !important;
    //     clip-path: none !important;
    //   }
    //   .tm_hero .content h3 span {
    //     -webkit-text-stroke: 1px #fff;
    //   }
    //   .tm_hero .content h3 {
    //     color: #fff;
    //     position: relative;
    //     overflow: hidden;
    //   }
    //   .tm_topbar .topbar_inner .logo a img {
    //     filter: invert(1);
    //   }
    
    //   .tm_topbar .menu ul li a {
    //     color: #fff;
    //   }
    //   .tm_topbar .menu ul li.current a {
    //     color: #efefef;
    //   }
    //   .tm_topbar .topbar_inner.opened {
    //     background-color: #000;
    //   }
    //   .tm_topbar .topbar_inner {
    //     max-width: 1200px;
    //     margin: 0 auto;
    //   }
    //   .tm_hero .content .job {
    //     font-weight: 700;
    //     font-size: 35px;
    //     font-family: 'Montserrat';
    //     color: #fff;
    //     padding: 8px 0px 10px;
    //     @media (max-width: 1040px) {
    //       font-size: 20px;
    //       font-weight: 600;
    //       padding: 8px 0 0;
    //     }
    //   }
    //   .tm_hero .content {
    //     z-index: 3;
    //   }
    
    //   .tm_hero .content h3 {
    //     line-height: 1.3;
    //   }
    //   @media screen and (max-width: 767px) {
    //     .tm_hero .content h3 {
    //       font-size: 40px;
    //     }
    //     .tm_hero .content .job {
    //       font-size: 25px;
    //       padding-top: 0;
    //     }
    //   }
    // }
  },

  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: `center`,
    alignItems: `center`,
    padding: 0,
    margin: 0,
  },
  formContainer: {
    flex: 1,
    display: `flex`,
    justifyContent: `center`,
    flexWrap: `wrap`,
    [theme.breakpoints.down('xs')]: {
      minHeight: `100vh`,
      background: theme.palette.background.login,
      boxShadow: `none`,
      display: `flex`,
      alignItems: `center`,
    },
  },
  formContent: {
    maxWidth: 600,
    [theme.breakpoints.down('xs')]: {
      background: `none`,
      boxShadow: `none`,
    },
  },
  loginForm: {
    margin: '0 auto',
    width: '86% !important',
    '& label': {
      display: `none`,
    },
    '& button': {
      width: `100%`,
    },
  },
  logoContainer: {
    width: `100%`,
  },
  logoImg: {
    border: 'none',
    boxShadow: 'none',
    color: theme.palette.icon[3],
    width: 'auto !important',
    display: `block`,
    maxWidth: 120,
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  logoImg2: {
    border: 'none',
    boxShadow: 'none',
    color: theme.palette.icon[3],
    width: 'auto !important',
    display: `block`,
    maxWidth: 260,
    // padding:"7.3rem 0 0rem 0",
    margin: '0 auto',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.between('xs', 'sm')]: {
      // marginTop:theme.spacing(1),
    },
  },
  logoTitle: {
    width: '90%',
    fontSize: '2em',
    color: theme.palette.brandColor,
    letterSpacing: 0,
    lineHeight: '2em',
    margin: '0 auto',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    textAlign: 'center',
  },
  cardBottom: {
    width: '100%',
    // position: 'absolute',
    // bottom: 100,
    marginTop: theme.spacing(13),
    textAlign: `center`,
    color: theme.palette.text[4],
    letterSpacing: 0,
    lineHeight: 1.3,
    [theme.breakpoints.down('xs')]: {
      // for mobile,ipad
      marginTop: theme.spacing(5),
      fontSize: '0.75rem',
      marginBottom: theme.spacing(5.875),
    },
  },
  errorMessage: {
    // error from server
    color: theme.palette.text[7],
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontFamily: 'Avenir-Medium',
    textAlign: 'center',
  },
  errorText: {
    color: theme.palette.text[7],
    marginTop: theme.spacing(0.6),
  },
  mask: {
    width: '100%',
    height: '100%',
    background: 'rgba(0,61,165,0.15)',
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(9.875),
    },
  },
  coutMask: {
    paddingTop: theme.spacing(11.25),
  },
}));

export default HomeStyles;
