import React from 'react';
import { useState } from 'react';
import ReactMapGL from 'react-map-gl';

const Map = () => {
  const [viewport, setViewport] = useState({
    latitude: 49.167282,
    longitude: -123.1484767,
    zoom: 14,
  });

  const [settings, setsettings] = useState({
    dragPan: false,
    dragRotate: false,
    scrollZoom: false,
    touchZoom: false,
    touchRotate: false,
    keyboard: false,
    doubleClickZoom: false,
  });

  return (
    <div className='tm_section' id='location'>
      <div className='tm_map'>
        <h3>
          The Chapel At Minoru Park, <br />
          Vancouver, Canada
        </h3>
        <p>6540 Gilbert Rd., Richmond, BC V7C 3V4</p>
        <div className='map'>
          <ReactMapGL
            mapboxApiAccessToken='pk.eyJ1IjoiYmF5YXppZGgiLCJhIjoiY2tvemdwc3ByMDg1YzJubzQxcDR0cDR3dyJ9.s1zXEb5OPqgBDcmupj3GBA'
            {...viewport}
            {...settings}
            onViewportChange={nextViewport => setViewport(nextViewport)}
            mapStyle='mapbox://styles/mapbox/streets-v9'
          />
        </div>
      </div>
      {/* End container */}
    </div>
  );
};

export default Map;
