import { axiosInstance } from 'utility/constants';

export const searchInvoiceTypeByPage = (pageSize = 1000, pageIndex = 1, searchData) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/item_type/list`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      limit: pageSize,
      page: pageIndex + 1,
      filters: {
        ...searchData,
      },
    },
  });

export const createInvoiceType = (data) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/item_type/add`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      data,
    },
  });

export const getInvoiceTypeById = (id) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/item_type/view/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const updateInvoiceType = (id, data) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/item_type/update/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      data,
    },
  });

export const deleteInvoiceType = (id) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/item_type/delete/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
  });
