import React, { useEffect } from 'react';
import moment from 'moment';
import { BrowserRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CssBaseline } from '@material-ui/core';
import { LocalizationProvider } from '@material-ui/pickers';
import MomentAdapter from '@material-ui/pickers/adapter/moment';
import Router from 'router';
import { ThemeProvider } from '@material-ui/styles';
import ApiHandlerProvider from 'core/store/providers/ApiHandler';
import ScrollToTop from 'components/ScrollToTop';
import LightTheme from './styles/LightTheme';

const Main = () => {
  const { t, i18n } = useTranslation();
  const currentTheme = LightTheme;

  const initApiState = {
    isLoading: false,
    isError: false,
    toastList: [],
    errorPath: '',
  };

  useEffect(() => {
    document.title = t('commons.defaultPageHeader');
  }, [t]);

  return (
    <ThemeProvider theme={currentTheme}>
      <LocalizationProvider dateLibInstance={moment} dateAdapter={MomentAdapter} locale={i18n.language}>
        <ApiHandlerProvider initState={initApiState}>
          <BrowserRouter basename='/'>
            <CssBaseline />
            <ScrollToTop />
            <Router />
          </BrowserRouter>
        </ApiHandlerProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default Main;
