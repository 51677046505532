import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import useForm from 'core/store/hooks/useForm';
import useApiHandler from 'core/store/hooks/useApiHandler';
import { AuthContext } from 'core/store/providers/Auth';
import { LoginWithID } from 'core/services/Auth';
import { DEFAULT_PAGE_PATH } from 'utility/constants';

const useAuth = () => {
  const history = useHistory();
  const Auth = useContext(AuthContext);
  const { state: AuthState, dispatch } = Auth;
  const { formInputs } = AuthState;
  const { checkAllValid } = useForm(Auth);
  const { dispatch: apiHandlerDispatch } = useApiHandler();
  const userStorage = JSON.parse(window.sessionStorage.getItem('user'));
  const roles = userStorage && userStorage?.estateInfo && userStorage?.estateInfo[0]?.roles;

  const handleLogin = () => {
    dispatch({ type: 'SET_TOUCHED' });
    const tmpValid = checkAllValid(formInputs);
    const { loginId, password, estate } = formInputs.login?.fields;

    if (tmpValid) {
      const loginIdValue = loginId?.value;
      const passwordValue = password?.value;
      const estateValue = loginId === 'admin' ? 0 : estate?.value?.value;

      LoginWithID(loginIdValue, passwordValue, estateValue)
        .then((res) => {
          if (
            (res.status === 200 && Object.keys(res?.data?.data?.user).length > 0) ||
            (res.status === 204 && Object.keys(res?.data?.data?.user).length > 0)
          ) {
            console.log(res?.data?.data);
            dispatch({ type: 'SUCCESS_LOGIN', loginData: res?.data?.data });
            history.replace(DEFAULT_PAGE_PATH);
          } else {
            apiHandlerDispatch({ type: 'HANDLE_ERROR', error: res?.data });
          }
        })
        .catch((error) => apiHandlerDispatch({ type: 'HANDLE_ERROR', error }));
    }
  };

  return { Auth, AuthState, dispatch, handleLogin, userStorage, roles };
};

export default useAuth;
