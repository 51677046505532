import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import useAuth from 'core/store/hooks/useAuth';
import useRoutes from 'core/store/hooks/useRoutes';
import useApiHandler from 'core/store/hooks/useApiHandler';
// import NotificationPopUp from 'components/NotificationPopUp';
import MainLayout from 'core/pages/_layout/MainLayout';
import AuthLayout from 'core/pages/_layout/AuthLayout';
import { DEFAULT_PAGE_PATH } from 'utility/constants';
import NotFoundPage from 'core/pages/NotFoundPage';
//  Add core routes, don't need to touch
import { AuthLayoutRoutes } from 'core/routes';

const authRoutes = (Layout, routes) =>
  routes.map(({ path, component: Component, id }) => (
    <Route
      exact
      key={id}
      path={path}
      render={props => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  ));

const childRoutes = (routes, Layout) =>
  routes.map(({ component: Component, children, id: filteredId, path: filteredPath }) =>
    children ? (
      // Route item with children
      children.map(({ component: ChildComponent, id: childId, path: childPath }) => (
        <Route
          key={childId}
          path={childPath}
          exact
          render={props => (
            <Layout>
              <ChildComponent {...props} />
            </Layout>
          )}
        />
      ))
    ) : (
      <Route
        exact
        key={filteredId}
        path={filteredPath}
        render={props => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    ),
  );

const Router = () => {
  const { AuthState, userStorage } = useAuth();
  const { ApiHandlerState } = useApiHandler();
  const { filteredRoutes } = useRoutes();

  return (
    <BrowserRouter basename='/'>
      {/* {ApiHandlerState.toastList.length > 0 && (
        <NotificationPopUp toastList={ApiHandlerState.toastList} position='top-right' />
      )} */}

      <Switch>
        {authRoutes(AuthLayout, AuthLayoutRoutes)}
        {userStorage?.status === 'AUTHENTICATED' && AuthState && childRoutes(filteredRoutes, MainLayout)}
        {userStorage?.status === 'AUTHENTICATED' && AuthState && (
          <Route exact path='/'>
            <Redirect to={DEFAULT_PAGE_PATH} />
          </Route>
        )}
        {!userStorage && <Redirect to='/' />}
        <Route component={NotFoundPage} />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
