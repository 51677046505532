import useApiHandler from 'core/store/hooks/useApiHandler';
import { searchEstateByPage, searchEstateByPageAll } from 'core/services/Property/Estate';
import { searchBuildingByPage } from 'core/services/Property/Building';
import { searchFloorByPage } from 'core/services/Property/Floor';
import { searchUnitByPage } from 'core/services/Property/Unit';
import { searchNoticeCategoryByPage } from 'core/services/Notice/NoticeCategory';
import { searchNoticeTypeByPage } from 'core/services/Notice/NoticeType';
import { searchInvoiceTypeByPage } from 'core/services/Invoice/InvoiceType';
import { searchInvoiceCategoryByPage } from 'core/services/Invoice/InvoiceCategory';
import { searchTemplateByPage } from 'core/services/Admin/Template';
import { searchFacilityByPage } from 'core/services/Facility/Facility';
import { searchFacilityTypeByPage } from 'core/services/Facility/FacilityType';
import { searchUserByPage } from 'core/services/Admin/User';

const useDropdown = (formContext) => {
  const dispatch = formContext?.dispatch;
  const { dispatch: apiHandlerDispatch } = useApiHandler();

  // rows per page
  const getAllEstateFromAPI = (fieldType, fieldName) =>
    searchEstateByPageAll()
      .then((res) =>
        fieldType === 'search'
          ? dispatch({ type: 'INIT_SEARCH_ESTATES', estates: res?.data?.data?.data, fieldName })
          : dispatch({ type: 'INIT_FORM_ESTATES', estates: res?.data?.data?.data, fieldName }),
      )
      .catch((error) => apiHandlerDispatch({ type: 'HANDLE_ERROR', error }));

  const getEstateFromAPI = (fieldType, fieldName) =>
    searchEstateByPage()
      .then((res) =>
        fieldType === 'search'
          ? dispatch({ type: 'INIT_SEARCH_ESTATES', estates: res?.data?.data?.data, fieldName })
          : dispatch({ type: 'INIT_FORM_ESTATES', estates: res?.data?.data?.data, fieldName }),
      )
      .catch((error) => apiHandlerDispatch({ type: 'HANDLE_ERROR', error }));

  const filterBuilding = (estate, fieldType, fieldName) =>
    searchBuildingByPage()
      .then((res) =>
        fieldType === 'search'
          ? dispatch({ type: 'INIT_SEARCH_BUILDING', estate, buildings: res?.data?.data?.data, fieldName })
          : dispatch({ type: 'INIT_FORM_BUILDINGS', estate, buildings: res?.data?.data?.data, fieldName }),
      )
      .catch((error) => apiHandlerDispatch({ type: 'HANDLE_ERROR', error }));

  const filterFloor = (building, fieldType, fieldName) =>
    searchFloorByPage()
      .then((res) =>
        fieldType === 'search'
          ? dispatch({ type: 'INIT_SEARCH_FLOORS', building, floors: res?.data?.data?.data, fieldName })
          : dispatch({ type: 'INIT_FORM_FLOORS', building, floors: res?.data?.data?.data, fieldName }),
      )
      .catch((error) => apiHandlerDispatch({ type: 'HANDLE_ERROR', error }));

  const filterUnit = (floor, fieldType, fieldName) =>
    searchUnitByPage()
      .then((res) =>
        fieldType === 'search'
          ? dispatch({ type: 'INIT_SEARCH_UNITS', floor, units: res?.data?.data?.data, fieldName })
          : dispatch({ type: 'INIT_FORM_UNITS', floor, units: res?.data?.data?.data, fieldName }),
      )
      .catch((error) => apiHandlerDispatch({ type: 'HANDLE_ERROR', error }));

  const getTenantFromAPI = (fieldType, fieldName) =>
    searchUserByPage(1000, 0, {}, 'tenant')
      .then((res) =>
        fieldType === 'search'
          ? dispatch({ type: 'INIT_SEARCH_TENANT', tenants: res?.data?.data?.data, fieldName })
          : dispatch({ type: 'INIT_FORM_TENANT', tenants: res?.data?.data?.data, fieldName }),
      )
      .catch((error) => apiHandlerDispatch({ type: 'HANDLE_ERROR', error }));

  const getNoticeCategoryFromAPI = (fieldType, fieldName) =>
    searchNoticeCategoryByPage()
      .then((res) =>
        fieldType === 'search'
          ? dispatch({ type: 'INIT_SEARCH_NOTICE_CATEGORY', noticeCategories: res?.data?.data?.data, fieldName })
          : dispatch({ type: 'INIT_FORM_NOTICE_CATEGORY', noticeCategories: res?.data?.data?.data, fieldName }),
      )
      .catch((error) => apiHandlerDispatch({ type: 'HANDLE_ERROR', error }));

  const getNoticeTypeFromAPI = (fieldType, fieldName) =>
    searchNoticeTypeByPage()
      .then((res) =>
        fieldType === 'search'
          ? dispatch({ type: 'INIT_SEARCH_NOTICE_TYPE', noticeTypes: res?.data?.data?.data, fieldName })
          : dispatch({ type: 'INIT_FORM_NOTICE_TYPE', noticeTypes: res?.data?.data?.data, fieldName }),
      )
      .catch((error) => apiHandlerDispatch({ type: 'HANDLE_ERROR', error }));

  const getInvoiceTypeFromAPI = (fieldType, fieldName) =>
    searchInvoiceTypeByPage()
      .then((res) =>
        fieldType === 'search'
          ? dispatch({ type: 'INIT_SEARCH_INVOICE_TYPE', invoiceTypes: res?.data?.data?.data, fieldName })
          : dispatch({ type: 'INIT_FORM_INVOICE_TYPE', invoiceTypes: res?.data?.data?.data, fieldName }),
      )
      .catch((error) => apiHandlerDispatch({ type: 'HANDLE_ERROR', error }));

  const getInvoiceCategoryFromAPI = (fieldType, fieldName) =>
    searchInvoiceCategoryByPage()
      .then((res) =>
        fieldType === 'search'
          ? dispatch({ type: 'INIT_SEARCH_INVOICE_CATEGORY', invoiceCategories: res?.data?.data?.data, fieldName })
          : dispatch({ type: 'INIT_FORM_INVOICE_CATEGORY', invoiceCategories: res?.data?.data?.data, fieldName }),
      )
      .catch((error) => apiHandlerDispatch({ type: 'HANDLE_ERROR', error }));

  const getTemplateFromAPI = (templateType, id) => {
    const data = {
      limit: 1000,
      page: 1,
      // filters: { estate: estateId },
    };
    searchTemplateByPage(data)
      .then((res) => {
        if (res?.data?.data) {
          dispatch({ type: 'INIT_TEMPLATES_LINKS', templateLinks: res?.data?.data?.data, templateType, id });
        }
      })
      .catch((error) => apiHandlerDispatch({ type: 'HANDLE_ERROR', error }));
  };

  const getFacilityFromAPI = (fieldType, fieldName) =>
    searchFacilityByPage()
      .then((res) =>
        fieldType === 'search'
          ? dispatch({ type: 'INIT_SEARCH_FACILITY', facilities: res?.data?.data?.data, fieldName })
          : dispatch({ type: 'INIT_FORM_FACILITY', facilities: res?.data?.data?.data, fieldName }),
      )
      .catch((error) => apiHandlerDispatch({ type: 'HANDLE_ERROR', error }));

  const getFacilityTypeFromAPI = (fieldType, fieldName) =>
    searchFacilityTypeByPage()
      .then((res) =>
        fieldType === 'search'
          ? dispatch({ type: 'INIT_SEARCH_FACILITY_TYPE', facilityTypes: res?.data?.data?.data, fieldName })
          : dispatch({ type: 'INIT_FORM_FACILITY_TYPE', facilityTypes: res?.data?.data?.data, fieldName }),
      )
      .catch((error) => apiHandlerDispatch({ type: 'HANDLE_ERROR', error }));

  return {
    getAllEstateFromAPI,
    getEstateFromAPI,
    filterBuilding,
    filterFloor,
    filterUnit,
    getTenantFromAPI,
    getNoticeCategoryFromAPI,
    getNoticeTypeFromAPI,
    getInvoiceTypeFromAPI,
    getInvoiceCategoryFromAPI,
    getTemplateFromAPI,
    getFacilityFromAPI,
    getFacilityTypeFromAPI,
  };
};

export default useDropdown;
