import { makeStyles } from '@material-ui/styles';

const AuthLayoutStyles = makeStyles(() => ({
  mainContainer: {
    width: '100%',
    minHeight: '100vh',
  },
}));

export default AuthLayoutStyles;
