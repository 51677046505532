import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import useApp from 'core/store/hooks/useApp';
import MainLayoutStyles from 'core/pages/_layout/styles/MainLayout';
// import NavBar from 'core/pages/_layout/NavBar'; // 导入header组件
// import BottomInfoBar from 'core/pages/_layout/BottomInfoBar';

const MainLayout = ({ children }) => {
  const classes = MainLayoutStyles();
  const { AppState, dispatch } = useApp();
  const handleDrawerToggle = () => dispatch({ type: 'SET_TOGGLE_DRAWER' });

  return (
    <Grid container className={classes.mainContainer}>
      {/* <NavBar handleDrawerToggle={handleDrawerToggle} toggleDrawer={AppState?.toggleDrawer} /> */}
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: AppState?.toggleDrawer,
        })}>
        <Grid container className={classes.main}>
          <Grid item lg={12} md={12} className={classes.centerContent}>
            {children}
          </Grid>
        </Grid>
        {/* <BottomInfoBar /> */}
      </main>
    </Grid>
  );
};

export default MainLayout;

MainLayout.propTypes = {
  children: PropTypes.objectOf(PropTypes.any).isRequired,
};
