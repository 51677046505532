import { axiosInstance } from 'utility/constants';

export const searchTemplateByPage = (pageSize, pageIndex, data) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/template/list`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  });

export const getTemplateById = (id) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/template/view/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const createTemplate = (name, estate, fileId) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/template/add`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      data: {
        name,
        estate,
        file: fileId,
      },
    },
  });

export const updateTemplate = (templateId, data) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/template/update/${templateId}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      data,
    },
  });

export const deleteTemplate = (id) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/manager/template/delete/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
  });
