import { axiosInstance } from 'utility/constants';

export const LoginWithID = (loginId, password, estate) =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/auth/login`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      login: loginId,
      password,
      estate,
    },
  });

export const Logout = () =>
  axiosInstance({
    method: 'POST',
    url: `/api/cms/auth/logout`,
    headers: {
      'Content-Type': 'application/json',
    },
  });